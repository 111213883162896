<div [class]="classPrefix('MainPod')">

  <section [class]="classPrefix('Pod0')">

    <div [class]="classPrefix('Pod0Item0')">
      <h2 [class]="classPrefix('Pod0Title1')"><span>
        Capability Statement
      </span></h2>
      <section [class]="classPrefix('Pod8')">
        <div [class]="classPrefix('Pod8Item0')">
          <div [class]="classPrefix('Pod8Item1')">
            <img [class]="classPrefix('Pod8Img0')" src="assets/media/capabilityStatementZero/logo-no-bg.png" alt="">
            <h3 [class]="classPrefix('Pod8Title0')">Windmillcode</h3>
          </div>
          <h4 [class]="classPrefix('Pod8Title1')">BRINGING INNOVATIVE SOLUTIONS TO YOUR BUSINESS</h4>
          <div [class]="classPrefix('Pod8Item2')"></div>

        </div>
      </section>
      <img [class]="classPrefix('Pod0Img0')" src="assets/media/capabilityStatementZero/dreamstime_m_328737966.jpg" >

    </div>
  </section>
  <section [class]="classPrefix('Pod1')">
    <div [class]="classPrefix('Pod1Item0')">

      <ng-container
        [ngTemplateOutlet]="labelOne"
        [ngTemplateOutletContext]="{
          myLabel:'COMPANY OVERVIEW',
          containerClass:this.classPrefix('Pod1Item1')
        }"
      ></ng-container>
      <div [class]="classPrefix('Pod1Item2')">
        <p>Windmillcode excels in providing software content automation across multiple platforms, streamlining social media management for businesses. Their expertise spans platforms like Facebook, Twitter, Instagram, and more, offering seamless automation on over 20 sites. Additionally, their B2B software distribution model delivers licensed solutions that help businesses integrate reusable software to address modern development needs.</p>
        <p>In software consulting, Windmillcode works with small businesses to offer comprehensive services ranging from DNS repair to full-scale applications with website, server, and database integration. Their commitment to software education is reflected in their YouTube channel, where they provide valuable tutorials to over 700 subscribers, assisting individuals at all skill levels in entering the tech industry.</p>
      </div>
    </div>
  </section>
  <section [class]="classPrefix('Pod2')">

    <div [class]="classPrefix('Pod2Item0')">
      <ng-container
        [ngTemplateOutlet]="labelOne"
        [ngTemplateOutletContext]="{
          myLabel:'CORE OFFERINGS',
          containerClass:this.classPrefix('Pod2Item1')
        }"
      ></ng-container>
      <ul [class]="classPrefix('Pod2Item2')">
        <li>Web App Development</li>
        <li>Mobile App Development</li>
        <li>Browser Extension Development</li>
        <li>Video Editing Services</li>
        <li>On-Demand IT Services</li>
        <li>Workshop and Education Services</li>
      </ul>
    </div>
  </section>
  <section [class]="classPrefix('Pod3')">

    <div [class]="classPrefix('Pod3Item0')">
      <ng-container
        [ngTemplateOutlet]="labelOne"
        [ngTemplateOutletContext]="{
          myLabel:'KEY DIFFERENTIATORS',
          containerClass:this.classPrefix('Pod3Item1')
        }"
      ></ng-container>
      <ul [class]="classPrefix('Pod3Item2')">
        <li>3D Web Feature Integrations</li>
        <li>Advanced Web Animation Integrations</li>
        <li>Reusable Plugin Architecture</li>

      </ul>
    </div>
  </section>
  <section [class]="classPrefix('Pod4')">

    <div [class]="classPrefix('Pod4Item0')">
      <ng-container
        [ngTemplateOutlet]="labelOne"
        [ngTemplateOutletContext]="{
          myLabel:'PAST PROJECTS',
          containerClass:this.classPrefix('Pod4Item1')
        }"
      ></ng-container>
      <ul [class]="classPrefix('Pod4Item2')">
        <li>
          <h5>Eneobia - Bulk Content Management Platform</h5>
          <a href="https://eneobia.com">eneobia.com</a>
        </li>
        <li>
          <h5>FindMyRole - AI Powered Interview App</h5>
          <a href="https://findmyrole-preview.web.app/">findmyrole-preview.web.app</a>
        </li>
        <li>
          <h5>AI Prompt Modifer - AI Productivity Tool</h5>
          <a href="https://ai-prompt-modifier/">ai-prompt-modifier.com</a>
        </li>
        <li>
          <h5>Windmillcode CDK - Web Plugin Library</h5>
          <a href="https://windmillcode.github.io/Windmillcode-Angular-CDK-Docs">windmillcode.github.io/Windmillcode-Angular-CDK-Docs</a>
        </li>


      </ul>
    </div>
  </section>
  <div [class]="classPrefix('MainItem0')"></div>


  <section [class]="classPrefix('Pod6')">
    <div [class]="classPrefix('Pod6Item0')">
      <div [class]="classPrefix('Pod6Item1')">
        <h2 [class]="classPrefix('Pod6Title0')">COMPANY DATA</h2>
        <ul>
          <div>
            <li>- BUSINESS NAME: <span>Windmillcode LLC</span></li>
          </div>
          <div>
            <li>- UEI: <span>TQBDW2DUS9H5</span></li>
          </div>
          <div>
            <li>- CAGE CODE: <span>9T7M8</span></li>
          </div>
        </ul>
      </div>
      <div [class]="classPrefix('Pod6Item1')">
        <h2 [class]="classPrefix('Pod6Title0')">CONTACT INFOMRATION</h2>
        <ul>
          <div>
            <li>- EMAIL: <span>contact&#64;windmillcode.com</span></li>
          </div>
          <div>
            <li>- WEBSITE: <span>windmillcode.com</span></li>
          </div>
          <div>
            <li>- PHONE: <span>302-601-1082</span></li>
          </div>
        </ul>
      </div>
      <div [class]="classPrefix('Pod6Item1')">
        <h2 [class]="classPrefix('Pod6Title0')">NAICS CODES</h2>
        <ul>
          <div>
            <li>- 541511: <span>CUSTOM COMPUTER PROGRAMMING SERVICES</span></li>
          </div>
          <div>
            <li>- 541512: <span>COMPUTER SYSTEMS DESIGN SERVICES</span></li>
          </div>
          <div>
            <li>- 541519: <span>OTHER COMPUTER RELATED SERVICES</span></li>
          </div>
        </ul>
      </div>
      <div [class]="classPrefix('Pod6Item1')">
        <h2 [class]="classPrefix('Pod6Title0')">PSC CODES</h2>
        <ul>
          <div>
            <li>- U009: <span>EDUCATION/TRAINING- GENERAL</span></li>
          </div>
          <div>
            <li>- 7A21: <span>IT AND TELECOM - BUSINESS APPLICATION SOFTWARE (PERPETUAL LICENSE SOFTWARE)</span></li>
          </div>
          <div>
            <li>- U008: <span>EDUCATION/TRAINING- TRAINING/CURRICULUM DEVELOPMENT</span></li>
          </div>
          <div>
            <li>- T001: <span>PHOTO/MAP/PRINT/PUBLICATION- ARTS/GRAPHICS</span></li>
          </div>
          <div>
            <li>- 7A20: <span>IT AND TELECOM - APPLICATION DEVELOPMENT SOFTWARE (PERPETUAL LICENSE SOFTWARE)</span></li>
          </div>
        </ul>
      </div>

    </div>
  </section>
  <section [class]="classPrefix('Pod7')">
    <div [class]="classPrefix('Pod7Item0')">
      <h3 [class]="classPrefix('Pod7Title0')">Windmillcode</h3>
    </div>
  </section>

</div>


<ng-template #labelOne let-myLabel="myLabel" let-containerClass="containerClass">
  <div [class]="containerClass">
    <h2 [class]="classPrefix('Pod1Title0')">{{ myLabel }}</h2>
  </div>
</ng-template>
