import { silenceAllConsoleLogs, traverseClassAndRemoveUpdateAutomation } from "@core/utility/env-utils"
import { DevEnv } from "./environment.dev"


export let environment = {
  production: true
}
class ProdEnv extends DevEnv  {


  constructor(){
    super()
    this.type = "PROD"
    this.frontendURI0.url.host = "ai-prompt-modifier.com"
    this.backendURI0.url.host = "chatgpt-prompt-modifer.onrender.com"
    ;[this.frontendURI0,this.backendURI0].forEach((uri)=>{
      uri.url.port = "443"
    })
    this.firebase.storageImageURL ="https://firebasestorage.googleapis.com/v0/b/modify-chatgpt-prompts-prod.appspot.com/o/"

    silenceAllConsoleLogs()
    traverseClassAndRemoveUpdateAutomation(this)
    this.mobile.android.googlePlayStore.storeUrl ="https://play.google.com/store/apps/details?id=com.windmillcode.modifyChatGPTPrompts"



  }
}


export let ENV =   new ProdEnv()
