{
  "global": {
    "title": "AI Prompt Modifier",
    "website": "",
    "logoImgAlt": "Logo Img",
    "errorRequired": "This field is required",
    "errorEmail": "Please provide a valid email",
    "errrorMin0": "At least one value must be provided",
    "avatarImgAlt": "Avatar Img",
    "wmlChipsplaceholder": "Type your value then press enter to see it appear",
    "wmlChipsuserInputsAriaLabel": "User Inputs",
    "wmlChipsremoveChipAriaLabel": "remove",
    "fillOutForm": "Please fill out all required fields",
    "loginAgain": "Please login again to continue using your account",
    "logoutError": "There was an issue while trying to log you out of your account please try again or contact support if the issue persists",
    "overlayLoading": "Loading ...",
    "siteUnderConstruction": "Site Under Construction",
    "formSubmitSuccess": "Form Submitted Sucessfully",
    "systemError": "An error has occured please try again later or contact support if the issue persists",
    "serviceUnavailableError": "The resource you requested is not available due to system maintenance, please try again in a few minutes",
    "submitBtn": "Submit",
    "comingSoon": "This feature is not availble yet, please try again later",
    "na": "N\\A",
    "countries": [
      "US"
    ],
    "add": "Add",
    "remove": "Remove",
    "view": "View",
    "of": "of",
    "saveBtn": "Saved!",
    "saveBtnNotSaved": "Save?",
    "applyChanges": "Apply Changes",
    "back": "Back",
    "link": "Link",
    "download": "Download",
    "progress": "Progress",
    "email": "Email",
    "states": [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY"
    ],
    "langAbbreviations": [
      "EN",
      "AM",
      "BN",
      "DE",
      "ES",
      "FR",
      "HI",
      "MS",
      "SW",
      "UK",
      "ZH"
    ],
    "wmlSelect": {
      "select": "Please Select"
    },
    "WMLNotifyOne": [],
    "wmlChipsZero": {
      "placeholder": "Type your value then press enter to see it appear",
      "clearBtn": "Clear"
    },
    "wmlFileUpload": {
      "dragDropText": "Drag and drop the file(s) here or click on \"Browse Files\".",
      "browseFileText": "Browse Files"
    },
    "registerAgreement": [
      "By using our services, you acknowledge that you have read, understood, and agree to our",
      "Terms of Service",
      "and ",
      "Privacy Policy"
    ],
    "badges": {
      "chromeWebStore": "Chrome Web Store Badge",
      "edgeAddons": "Edge Addons Badge",
      "firefoxAddons": "Firefox Addons Badge",
      "safariAddons": "Safari Addons Badge"
    }
  },
  "ConfirmDialogZero": {
    "title": "WARNING! This action cannot be undone. Are you sure you wish to proceed",
    "subtitle": "",
    "options": [
      "Yes",
      "No"
    ]
  },
  "nav": {
    "pageInfo": {
      "default": {
        "title": "AI Prompt Modifier",
        "description": "Manage and optimize your prompts seamlessly across all platforms with the AI Prompt Modifier. Easily bulk edit, customize, and manage prompt data to streamline content creation.",
        "keywords": "Prompt management, AI Prompt Modifier, AI prompt tools, bulk editing prompts, prompt editor, prompt customization, content management, workflow optimization"
      },
      "landing": {
        "title": "AI Prompt Modifier Home",
        "description": "Welcome to the AI Prompt Modifier, a powerful tool for modifying and managing AI prompts in bulk. Discover how it helps content creators streamline their workflows.",
        "keywords": "Prompt management, AI Prompt Modifier, AI tools, bulk editing prompts, AI content management, workflow automation, prompt customization, digital assets"
      },
      "siteOffline": {
        "title": "AI Prompt Modifier Site Offline",
        "description": "The AI Prompt Modifier site is currently undergoing maintenance to improve your experience. Please check back later as we work to restore access.",
        "keywords": "AI Prompt Modifier, site offline, website maintenance, service unavailable, downtime, maintenance update, system maintenance"
      },
      "notFound": {
        "title": "AI Prompt Modifier 404",
        "description": "The page you are looking for on AI Prompt Modifier could not be found. Please ensure the URL is correct or navigate back to the homepage.",
        "keywords": "AI Prompt Modifier, 404 error, page not found, missing page, broken link, error page"
      },
      "terms-of-service": {
        "title": "AI Prompt Modifier Terms of Service",
        "description": "Carefully review the terms of service outlining the rules and regulations for using AI Prompt Modifier to ensure proper usage of the platform.",
        "keywords": "AI Prompt Modifier, terms of service, legal terms, usage policy, platform rules, user agreement, terms and conditions"
      },
      "privacy-policy": {
        "title": "AI Prompt Modifier Privacy Policy",
        "description": "Understand how AI Prompt Modifier collects, stores, and protects your personal information and data, ensuring confidentiality and security for all users.",
        "keywords": "AI Prompt Modifier, privacy policy, data protection, user privacy, personal information, confidentiality, security policy"
      },
      "survey": {
        "title": "AI Prompt Modifier Survey",
        "description": "Take part in our survey to provide valuable feedback and help us improve the AI Prompt Modifier platform for a better user experience.",
        "keywords": "AI Prompt Modifier, user feedback, survey, feedback form, user opinion, platform improvement, questionnaire, user survey"
      },
      "guide": {
        "title": "AI Prompt Modifier Guide",
        "description": "Explore our detailed guide on how to use the AI Prompt Modifier effectively",
        "keywords": "AI Prompt Modifier, guide, how to use, tutorial, instruction manual, step-by-step guide, user instructions, prompt management guide"
      },
      "contact": {
        "title": "AI Prompt Modifier Contact",
        "description": "Need assistance? Reach out to the AI Prompt Modifier support team for help with any issues, inquiries, or feedback about the platform.",
        "keywords": "AI Prompt Modifier, contact support, customer service, help desk, technical support, assistance, user inquiries"
      }
    }
  },
  "siteOffine": {
    "title": "Site Offline",
    "subtitle": "The site is currently unavailable due to scheduled maintenance, Please try again later"
  },
  "notFound": {
    "lines": [
      "404",
      "Whoops! This is not what you were looking for",
      "But you just found our logo, thanks. Click on the logo to go back to the ",
      "home page."
    ],
    "someImgAlt": "Resume"
  },
  "GuideZero": {
    "contents": [
      "Accounts",
      "Subscriptions",
      "Prefixes & Suffixes",
      "Modifying Your Prompts",
      "Bulk Management"
    ]
  },
  "HomeZeroPage": {
    "title": [
      "AI Prompt",
      "Modifier"
    ],
    "subtitle": "Save time and get ChatGPT responses tailored exactly how you want without the need to retype your preferences."
  },
  "NavZero": {
    "desktopOptions": [
      "Home",
      "Guide",
      "Privacy Policy",
      "Terms of Service",
      "Contact Us"
    ]
  },
  "PrivacyPolicy": {
    "title": "Privacy Policy",
    "sections": [
      "Effective Date: October 5, 2024",
      "1. Introduction",
      "Welcome to the AI Prompt Modifier Extension (\"the Extension\"). We are committed to protecting your personal information and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our Extension.",
      "2. Information We Collect",
      "Personal Information",
      [
        "- Email Address: Collected during the sign-up process via Firebase Authentication to create and manage your account.",
        "- Password: Used in combination with your email to secure your account.",
        "- Payment Information: When you subscribe to our monthly service, we collect necessary payment details to process your subscription securely through our payment processor."
      ],
      "Usage Data",
      [
        "- Information about how you use the Extension, including the features you access and your interaction with the Extension, to improve our services."
      ],
      "3. How We Use Your Information",
      "We use the collected data for various purposes:",
      [
        "- Account Management: To create and maintain your user account.",
        "- Service Provision: To provide and maintain the functionalities of the Extension.",
        "- Payment Processing: To handle subscription payments securely.",
        "- Customer Support: To respond to your comments, questions, and provide customer service.",
        "- Improvement of Services: To analyze usage and enhance the Extension's performance and user experience.",
        "- Communication: To send you updates, newsletters, and other information that may be of interest to you."
      ],
      "4. Disclosure of Your Information",
      "We may share your information in the following circumstances:",
      [
        "- Service Providers: With third-party vendors and service providers who perform services on our behalf, such as payment processing and email communication.",
        "- Legal Obligations: If required by law, regulation, or legal process, such as a court order or subpoena.",
        "- Business Transfers: In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business."
      ],
      "5. Data Security",
      "We prioritize the security of your personal information and implement industry-standard security measures to protect it. However, no method of electronic transmission or storage is 100% secure, and we cannot guarantee absolute security.",
      "6. Your Choices and Rights",
      [
        "- Access and Update: You can access and update your personal information through your account settings.",
        "- Opt-Out: You may opt-out of receiving promotional communications by following the unsubscribe instructions provided in the emails.",
        "- Account Deletion: You can request the deletion of your account by contacting us at [Your Contact Email]."
      ],
      "7. Children's Privacy",
      "Our Extension is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13 years of age.",
      "8. International Data Transfers",
      "Your information, including personal data, may be transferred to and maintained on servers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ.",
      "9. Changes to This Privacy Policy",
      "We may update our Privacy Policy from time to time. We will notify you of any changes by updating the \"Effective Date\" at the top of this policy. You are advised to review this Privacy Policy periodically for any changes.",
      "10. Contact Us",
      "If you have any questions or concerns about this Privacy Policy, please contact us at:",
      [
        "- Email: contact@windmillcode.com",
        "- Address: 2810 N Church St, Wilmington, DE 19802, PMB 380240"
      ]
    ]
  },
  "TermsOfService": {
    "title": "Terms of Service",
    "sections": [
      "Effective Date: October 5, 2024",
      "Welcome to the AI Prompt Modifier Extension (\"the Extension\"). These Terms of Service (\"Terms\") govern your use of the Extension provided by [Your Company Name] (\"we,\" \"us,\" or \"our\"). By accessing or using the Extension, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Extension.",
      "1. Acceptance of Terms",
      "By creating an account and using the Extension, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy, which is incorporated herein by reference.",
      "2. Eligibility",
      "You must be at least 18 years old to use the Extension. By using the Extension, you represent and warrant that you meet this age requirement.",
      "3. Account Registration and Security",
      [
        "- To access the Extension's features, you must sign up via authentication using your email address and a password.",
        "- You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary.",
        "- You are responsible for maintaining the confidentiality of your login credentials.",
        "- You agree to notify us immediately of any unauthorized use of your account.",
        "- We are not liable for any loss or damage arising from your failure to comply with these obligations."
      ],
      "4. Subscription and Payment",
      [
        "- Access to the Extension requires payment of a monthly subscription fee (\"Subscription Fee\").",
        "- The Subscription Fee amount is specified at the time of purchase and may be subject to change with or without prior notice.",
        "- Payments are processed through secure third-party payment processors.",
        "- You must provide valid and current payment information.",
        "- By subscribing, you authorize us to charge your payment method for the Subscription Fee and any applicable taxes.",
        "- Your subscription will automatically renew at the end of each billing cycle unless you cancel it before the renewal date.",
        "- You authorize us to charge your payment method for the renewal Subscription Fee.",
        "- You may cancel your subscription at any time through your account settings.",
        "- Cancellations will take effect at the end of the current billing period.",
        "- Subscription Fees are non-refundable except as required by law."
      ],
      "5. License and Usage",
      "You agree not to:",
      [
        "- Modify, copy, or create derivative works of the Extension.",
        "- Reverse engineer or attempt to extract the source code of the Extension.",
        "- Use the Extension for any unlawful or prohibited activities.",
        "- Share your account or allow others to access the Extension using your credentials."
      ],
      "6. User Content and Conduct",
      [
        "- You are solely responsible for any content you input, submit, or modify using the Extension (\"User Content\").",
        "- You represent that you have all necessary rights to the User Content."
      ],
      "You agree not to use the Extension to:",
      [
        "- Upload or transmit harmful, offensive, or illegal content.",
        "- Violate any applicable laws or regulations.",
        "- Infringe upon the rights of others."
      ],
      "7. Intellectual Property Rights",
      [
        "- All intellectual property rights in the Extension, including but not limited to software, text, graphics, and logos, are owned by us or our licensors.",
        "- You are granted no right or license with respect to any trademarks, service marks, or logos."
      ],
      "8. Privacy Policy",
      [
        "- Your use of the Extension is subject to our Privacy Policy.",
        "- By using the Extension, you consent to the collection and use of information as outlined in the Privacy Policy."
      ],
      "9. Disclaimers",
      [
        "- The Extension is provided on an \"AS IS\" and \"AS AVAILABLE\" basis.",
        "- We make no warranties or representations about the accuracy or completeness of the Extension's content.",
        "- We disclaim all warranties, express or implied, including but not limited to merchantability, fitness for a particular purpose, and non-infringement."
      ],
      "10. Limitation of Liability",
      [
        "- To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Extension.",
        "- Our total liability to you for any damages shall not exceed the amount of the Subscription Fee paid by you in the preceding three months."
      ],
      "11. Indemnification",
      [
        "- You agree to indemnify, defend, and hold harmless us, our affiliates, and our respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses arising from your use of the Extension or violation of these Terms."
      ],
      "12. Termination",
      [
        "- You may terminate these Terms by canceling your subscription and discontinuing use of the Extension.",
        "- We reserve the right to suspend or terminate your access to the Extension at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or us.",
        "- Upon termination, your right to use the Extension will immediately cease.",
        "- Sections of these Terms that by their nature should survive termination shall continue to be in effect."
      ],
      "13. Modifications to Terms",
      [
        "- We may revise these Terms from time to time.",
        "- We will notify you of any material changes by posting the new Terms on our website or through the Extension.",
        "- Your continued use of the Extension after changes become effective constitutes your acceptance of the new Terms."
      ],
      "14. Governing Law",
      "These Terms are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.",
      "15. Dispute Resolution",
      [
        "- Before initiating any legal action, you agree to attempt to resolve the dispute informally by contacting us at [Your Contact Email].",
        "- Any disputes arising out of or relating to these Terms or the Extension shall be resolved through binding arbitration administered by [Arbitration Association] in accordance with its rules.",
        "- You agree to resolve disputes with us on an individual basis and waive any right to participate in a class action lawsuit or class-wide arbitration."
      ],
      "16. Miscellaneous",
      [
        "- These Terms and the Privacy Policy constitute the entire agreement between you and us regarding the Extension.",
        "- If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in full force and effect.",
        "- Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.",
        "- You may not assign your rights or obligations under these Terms without our prior written consent."
      ],
      "17. Contact Information",
      "If you have any questions or concerns about these Terms, please contact us at:",
      [
        "Windmillcode",
        "- Email: contact@windmillcode.com",
        "- Address: 2810 N Church St, Wilmington, DE 19802, PMB 380240"
      ]
    ]
  },
  "ContactZeroPage": {
    "title": "Contact Us",
    "mainForm": {
      "nameField": {
        "label": "Name",
        "error": {}
      },
      "emailField": {
        "label": "Email",
        "error": {}
      },
      "messageField": {
        "label": "Message",
        "error": {}
      }
    }
  }
}