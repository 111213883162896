import { ChangeDetectorRef, Injectable } from '@angular/core';
import { delay, distinctUntilChanged, finalize, fromEvent, map, Observable, of, startWith, Subject, takeUntil, tap } from 'rxjs';

// services

// reactive forms
import { FormArray, FormControl, FormGroup } from '@angular/forms';

// wml components

import {  WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { WMLSelectZeroComponent, WMLSelectZeroProps } from '@windmillcode/angular-wml-select';
import {  WMLPopupZeroProps } from '@windmillcode/angular-wml-popup';
import { WMLNotifyOneBarModel, WMLNotifyOneBarType, WMLNotifyOneService } from '@windmillcode/angular-wml-notify';
import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel, WMLCustomComponent,WMLImage } from '@windmillcode/wml-components-base';
import { WMLOptionZeroItemProps, WMLOptionsZeroComponent, WMLOptionsZeroProps } from '@windmillcode/angular-wml-options';
import { WMLChipsZeroProps, WMLChipsZeroComponent } from '@windmillcode/angular-wml-chips';
import { UtilityService } from '@core/utility/utility.service';
import { GenerateFieldProps,  createFieldString,  createI18nErrorMsgsBasedOnValidations,  createWMLFormZeroPropsField,  resetFormControls } from '@core/utility/form-utils';
import { WMLToggleZeroComponent, WMLToggleZeroProps } from '@windmillcode/angular-wml-toggle';
import { CSSVARS } from '@core/utility/common-utils';
import { EnvPlatformType } from '@core/utility/env-utils';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    public utilService:UtilityService,
    public WMLNotifyOneService:WMLNotifyOneService
  ) { }

  visitedLinks: string[] = [];
  appCdRef!:ChangeDetectorRef
  // @ts-ignore
  toggleOverlayLoadingSubj:Subject<boolean> =new Subject<boolean>()
  toggleOverlayLoadingSubj$ = this.toggleOverlayLoadingSubj.asObservable()
  .pipe(
    delay(300),
    tap((val)=> {
      this.appCdRef.detectChanges()
    })
  )

  updateOverlayLoadingText:string = "global.overlayLoading"
  closeOverlayLoadingViaRxjsFinalize = finalize(()=>{

    this.closeOverlayLoading()
  })

  closeOverlayLoading = ()=>{
    this.toggleOverlayLoadingSubj.next(false)
  }
  openOverlayLoading = ()=>{
    this.toggleOverlayLoadingSubj.next(true)
  }
  toggleMobileNavSubj = new Subject<boolean>()
  popupProps= new WMLPopupZeroProps({})

  openPopup =(props:WMLCustomComponent)=>{

    this.popupProps.cpnt = props.cpnt
    this.popupProps.props = props.props ?? {}
    this.popupProps.togglePopupSubj.next(true)
  }
  closePopup = ()=>{
    this.popupProps.togglePopupSubj.next(false)
  }

  /*
  to use
    image = ()=>{
      image =this.baseService.onImageFailedToLoad(image)
      this.cdref.detectChanges()
    }
  */
  onImageFailedToLoad = (image:WMLImage,fallback = new WMLImage({src:"assets/media/app/logo-no-bg.png"}) ) =>{
    image = Object.assign(image,fallback)
    return image
  }

  openFeatureIsComingSoon = ()=>{
    this.createWMLNote("global.comingSoon",WMLNotifyOneBarType.Info)
  }
  openSystemError = ()=>{
    this.createWMLNote("global.systemError",WMLNotifyOneBarType.Error)
  }

  createWMLNote = (i18nKey:string ="Success",type:WMLNotifyOneBarType=WMLNotifyOneBarType.Success,autoHide=true,autoOpen=true )=>{
    type = type ?? WMLNotifyOneBarType.Success
    let note =new WMLNotifyOneBarModel({
      type,
      autoHide,
      hideDelay:10000,
      message:i18nKey,
    })
    if(autoOpen){
      this.WMLNotifyOneService.create(note)
    }
    return  note
  }

  createWMLField=(props)=>{
    let {
      i18nPrefix,
      idPrefixFn,
      formControlName,
      createWMLFieldFn,
      fieldParentForm,
      errorMsgKeyArray,
      fieldCustomProps,
      labelValue
    } = props
    // TODO work on this major forms reafactor
    let formControlLabel: keyof typeof fieldParentForm.value;

    let val = createFieldString(formControlName)

    let field = (createWMLFieldFn ?? this.createInputFormField)({
      id:idPrefixFn(val.field),
      labelValue:labelValue??i18nPrefix +"."+val.field+".label",
      fieldFormControlName: formControlName,
      fieldParentForm,
      selfType: 'standalone',
      errorMsgs: createI18nErrorMsgsBasedOnValidations(
        i18nPrefix,
        val.field,
        errorMsgKeyArray??['replace', 'with', 'your', 'error', 'keys']
      ),
      fieldCustomProps
    })
    return field as WMLFieldZeroProps
  }

  createInputFormField=(props:GenerateFieldProps)=>{
    let wmlField = createWMLFormZeroPropsField(props)
    return wmlField
  }

  createTextAreaFormField=(props:GenerateFieldProps)=>{

    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.props.type  ="textarea"
    return wmlField
  }

  createRangeFormField=(props:GenerateFieldProps )=>{

    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.props.type  ="range"
    return wmlField
  }

  createCheckboxFormField=(props:GenerateFieldProps )=>{

    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.props.type  ="checkbox"
    return wmlField
  }

  createSelectField=(props:GenerateFieldProps<WMLSelectZeroProps>)=>{

    let {
      fieldCustomProps
    } = props
    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.cpnt = WMLSelectZeroComponent
    wmlField.field.custom.props  =fieldCustomProps ?? new WMLSelectZeroProps({
      select:"global.wmlSelect.select",
      wmlField
    })
    return wmlField
  }

  createOptionsFormField=(props:GenerateFieldProps<WMLOptionsZeroProps>)=>{

    let {
      fieldCustomProps
    } = props
    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.cpnt = WMLOptionsZeroComponent
    wmlField.field.custom.props  =fieldCustomProps ?? new WMLOptionsZeroProps({
      options:[new WMLOptionZeroItemProps({
        text:"use WMLOptionsZeroProps from the WMLOptionsZero component and fill me w/ options",
      })]
    })
    return wmlField
  }

  createChipsFormField=(props =new GenerateFieldProps<WMLChipsZeroProps>())=>{
    let {
      fieldCustomProps
    } = props
    fieldCustomProps = fieldCustomProps ?? new WMLChipsZeroProps({
      placeholder:"global.wmlChipsplaceholder",
      userInputsAriaLabel:"global.wmlChipsuserInputsAriaLabel",
      removeChipAriaLabel:"global.wmlChipsremoveChipAriaLabel",
    })
    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.cpnt = WMLChipsZeroComponent
    wmlField.field.custom.props  =fieldCustomProps
    return wmlField
  }

  createToggleField=(props =new GenerateFieldProps<WMLToggleZeroProps>())=>{
    let {
      fieldCustomProps
    } = props
    fieldCustomProps = fieldCustomProps ?? new WMLToggleZeroProps({
      toggleBackgroundOffColor:"rgba(var(--wml-toggle-off))",
      toggleBackgroundOnColor:"rgba(var(--wml-secondary))"
    })
    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.cpnt = WMLToggleZeroComponent
    wmlField.field.custom.props  =fieldCustomProps
    return wmlField
  }

  submitForm = (props=new BaseServiceSubmitFormProps())=>{
    let {rootFormGroup,cdref,validFormPredicateTypeCustom,invalidFormPredicate,openOverlayLoading,closeOverlayLoading,validateForm} = props


    if(!rootFormGroup.valid && validateForm){
      invalidFormPredicate ?invalidFormPredicate(): this.tellUserToFillOutRequiredFields(
        [rootFormGroup],cdref
      )
    }
    else{
      if(openOverlayLoading){
        this.openOverlayLoading()
      }
      if(props.validFormPredicateType === "default"){
        let {apiCall$,submitFormSuccess,submitFormFail,submitFormSuccessResetForm,submitFormSuccessNotify}= props.validFormPredicateTypeDefault
        apiCall$
        .pipe(
          takeUntil(props.validFormPredicateTypeDefault.ngUnsub),
          tap({
            next:submitFormSuccess ?? (()=>{
              if(submitFormSuccessNotify !== false){
                this.createWMLNote("global.formSubmitSuccess",WMLNotifyOneBarType.Success,true)
              }
              if(submitFormSuccessResetForm !== false){
                resetFormControls(rootFormGroup)
              }
            }),
            error:submitFormFail ?? (()=>{
              this.openSystemError()
            })
          }),
          closeOverlayLoading ? this.closeOverlayLoadingViaRxjsFinalize : tap()
        )
        .subscribe()
      }
      else{
        let result = validFormPredicateTypeCustom()
        if (result instanceof Observable){
          result.subscribe()
        }
      }

    }
  }

  tellUserToFillOutRequiredFields = (rootFormGroups:FormGroup[],cdref?:ChangeDetectorRef)=>{
    let fillOutFormNote =this.createWMLNote("global.fillOutForm",WMLNotifyOneBarType.Error)
    this.WMLNotifyOneService.create(fillOutFormNote)
    rootFormGroups.forEach((formGroup)=>{
      this.validateAllFormFields(formGroup)
    })
    cdref?.detectChanges()
  }


  validateAllFormFields(formGroup: FormGroup,validateSelf=false) {
    if(validateSelf){
      formGroup.markAsDirty({ onlySelf: true });
      formGroup.updateValueAndValidity({ emitEvent: true });
    }
    Object.keys(formGroup.controls).forEach(field => {
      // TODO we have a situation where formgroups can get passed in as children figure out how to resolve
      const control = formGroup.get(field);
      if (control instanceof FormControl || control instanceof FormArray) {
        control.markAsDirty({ onlySelf: true });
        control.updateValueAndValidity({ emitEvent: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  notifyUserThatThereAreNoRecords(apiBody: {data:WMLAPIPaginationResponseModel}, uiBody: WMLAPIPaginationRequestModel) {
    if (apiBody.data.totalItems === 0) {
      this.createWMLNote("EntityMain.WMLNotifyOne.noRecords");
    }
    else {
      uiBody.pageNum += 1;
    }
  }

  listenForWindowResize = ()=>{
    let window = this.utilService.getWindow()
    let mobile = window.matchMedia(CSSVARS.wmlMobile)
    let tablet = window.matchMedia(CSSVARS.wmlTablet);
    let desktop = window.matchMedia(CSSVARS.wmlDesktop)
    return fromEvent(window,"resize")
    .pipe(
      startWith(null),
      map(() => {
        if (mobile.matches) return 'mobile';
        if (tablet.matches) return 'tablet';
        return 'desktop';
      }),
      distinctUntilChanged(),
      map(()=>{
        return {
          desktop,tablet,mobile
        }
      })
    )

  }

  openApp(platform?:EnvPlatformType) {
    let window = this.utilService.getWindow()
    // Try to open the iOS app

    return of(null)
    .pipe(
      tap(()=>{
        window.location.href = 'myapp-ios://';
      }),
      delay(1000),
      tap(()=>{
        window.location.href = 'intent://...#Intent;package=com.windmillcode.modifyChatGPTPrompts;scheme=myapp;end';
      })
    )
  }

  checkPerformance = ()=>{
    return new Observable<boolean>(observer => {
      let start = performance.now();
      requestAnimationFrame(() => {
        let duration = performance.now() - start;
        observer.next(duration < 16.67); // Less than 16.67ms for 60fps
        observer.complete();
      });
    });
  }



}





export class BaseServiceSubmitFormProps {
  constructor(props:Partial<BaseServiceSubmitFormProps>={}){
    Object.assign(
      this,
      {
        ...props
      }
    )
  }

  rootFormGroup:FormGroup = new FormGroup({})
  cdref?:ChangeDetectorRef
  invalidFormPredicate?:Function
  openOverlayLoading = true
  closeOverlayLoading = true
  submitFormErrorPredicate:Function
  validateForm = true
  validFormPredicateType: "default" | "custom" ="default"
  validFormPredicateTypeCustom?:Function
  validFormPredicateTypeDefault: Partial<{
    apiCall$:Observable<any>,
    ngUnsub:Subject<any>
    submitFormSuccess:(value: any) => void
    submitFormSuccessResetForm:boolean
    submitFormSuccessNotify:boolean
    submitFormFail:(value: any) => void
  }>
}
