import { SITE_OFFLINE_ENUM } from '@core/utility/constants';
import { EnvPlatformType, traverseClassAndRemoveUpdateAutomation } from '@core/utility/env-utils';
import { makeTitleCase } from '@core/utility/string-utils';
import {WMLEndpoint,WMLUri} from "@windmillcode/wml-components-base"


export let environment = {
  production: false,
};


export class DevEnv {
  // this must be a union type for import purposes
  type: 'DEV' | 'DOCKER_DEV' | 'PREVIEW' | 'PROD' | 'TEST' = 'DEV';
  platformType = EnvPlatformType.WEB
  endpointMsgCodes = {
    success: 'OK',
    error: 'ERROR',
    respViaSocketio: 'RESPONSE_VIA_SOCKETIO',
  };

  app = (() => {
    let app = {

      shouldPerformInitialNavigate:()=>{
        return navigator.userAgent ==="Puppeteer" || !["PREVIEW","PROD"].includes(this.type)
      },
      backendHealthCheck: () => this.backendURI0.fqdn + '/healthz/',
      siteOffline: SITE_OFFLINE_ENUM.FALSE,
      originalConsoleObj: [],
      keyBoardDebounceTimes:[500],
      isRemote:false //remember when setting to true you have to run the app in http because the emulator rejects your certificate as its not in the cert store of the remote device // TODO just know when deploying always set isRemote to false

      // dev additions


    };
    return app;
  })();


  backendURI0 = new WMLUri({
    host:"example.com",
    port:10072
  })
  frontendURI0 = new WMLUri({
    host:"example.com",
    port:10002
  })


  classPrefix = {
    app: 'App'
  };

  idPrefix: { [k: string]: string } = {
    logoImgZero: 'LogoImgZero_',
    landingLayout: 'LandingLayout_',
    legalDocPage: 'LegalDocPage_',
    surveyPage: 'SurveyPage_',
    guideZeroPage: 'GuideZeroPage_',
    verifyEmailZeroPage: 'VerifyEmailZeroPage_',
    notFound: 'NotFound_',
    siteOffline: 'SiteOffline_',
    landingZeroLayout:"LandingZeroLayout_",
    homeZeroPage:"HomeZeroPage_",
    badgeZero:"BadgeZero_",
    navZero:"NavZero_",
    threejsBackgroundZero:"ThreejsBackgroundZero_",
    contactZeroPage:"ContactZeroPage_",
    videoViewerZero:"VideoViewerZero_",
    cardOne:"CardOne_",
    capabilityStatementZero:"CapabilityStatementZero_"
  };

  nav = (() => {
    let nav = {
      urls: {

        home: '/',
        landing:"/",
        homeAlt: '/home',
        siteOffline: '/site-offline',
        notFound:"/404",
        initialURL: '/',
        accountLayout: '/account',
        legalDocPage:"/legal",
        termsOfServicePage:"/legal/terms-of-service",
        privacyPolicyPage:"/legal/privacy-policy",
        surveyPage:"/survey",
        guide:"/guide",
        contact:"/contact",


      },
    };

    let idPrefixes = Object.entries(nav.urls).map(([key, val]) => {
      return [key, makeTitleCase(key) + '_'];
    });
    this.idPrefix = {
      ...Object.fromEntries(idPrefixes),
      ...this.idPrefix,
    };
    return nav;
  })();

  mobile = {
    android:{
      appUrl:"",
      googlePlayStore:{
        storeUrl:'',

      }
    },
    ios:{
      appUrl:"",
      appStore:{
        storeUrl:""
      }
    }
  }

  browserExtensions ={
    chrome:{},
    firefox:{},
    safari:{},
    edge:{},
  }


  errorCodes = {};



  firebase = (() => {
    let firebase

    firebase = {
      config: {
        apiKey: 'AIzaSyDWW7K97Y72ge330v3jZ2ePg7ac1JJqzg0',
        authDomain: '127.0.0.1',
        projectId: 'modify-chatgpt-prompts-prod',
        storageBucket: "modify-chatgpt-prompts-prod.appspot.com",
        messagingSenderId: "603487996214",
        appId: "1:603487996214:web:fcea223d1d1f2d331e8b97",
        measurementId: "G-9X77ZSM40H"
      },
      get storageImageURL(){
        return firebase._storageImageURL
      },
      set storageImageURL(value){
        firebase._storageImageURL = value
      },
      getImageUrlFromStorage: (resourcePath?: string) => {
        return `${firebase.storageImageURL}${encodeURIComponent(
          resourcePath,
        )}?alt=media`;
      },
    };
    firebase.storageImageURL =`http://${firebase.config.authDomain}:9199/v0/b/modifyChatGPTPrompts-preview.appspot.com/o/`
    return firebase;
  })();

  contactService = {
    sendMessage:new WMLEndpoint({
      url:()=> this.backendURI0.fqdn + "/contact/messages/send",
    })
  }


  constructor() {
    this.app.originalConsoleObj = Object.entries(console);
    traverseClassAndRemoveUpdateAutomation(this)
    if(this.app.isRemote){
      this.backendURI0.url.host ='0xdaf8738d7d6dca8774f2f2742cbd7be913e9c3eb.diode.link'
      this.backendURI0.url.port ="443"
      this.firebase.config.authDomain ='192.168.1.90'
    }
  }
}

export let ENV = new DevEnv();
