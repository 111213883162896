<nav [class]="classPrefix('MainPod')">
  <ul [class]="classPrefix('Pod0')">
    <li
    [class]="classPrefix('Pod0Link0')"
    [id]="route.id"
    *ngFor="let route of navService.desktopNavZero">
      <a [routerLink]="route.routerLink">
        {{route.text | translate}}
      </a>
    </li>
  </ul>
</nav>
